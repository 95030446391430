/*
** Sleeve Default
** Global variables
*/

:root {
	--sleeve-card-border: none;
	
  //- Button styling
  --sleeve-btn-height: 50px;
  --sleeve-btn-min-width: 150px;
  --sleeve-btn-padding-y: 15px;
  --sleeve-btn-padding-x: 15px;
  --sleeve-btn--sm-height: 40px;
  --sleeve-btn--sm-min-width: auto;
  --sleeve-btn--sm-padding-y: 10px;
  --sleeve-btn--sm-padding-x: 10px;
  --sleeve-btn--lg-height: 60px;
  --sleeve-btn--lg-min-width: 200px;
  --sleeve-btn--lg-padding-y: 20px;
  --sleeve-btn--lg-padding-x: 20px;
  --sleeve-button-border-radius: 50px;
  
  //- Padding
  --sleeve-component-spacing: 30px;
  --sleeve-component-padding: 30px;
  --sleeve-component-padding-top: 30px;
  --sleeve-component-padding-right: 30px;
  --sleeve-component-padding-bottom: 30px;
  --sleeve-component-padding-left: 30px;

  //- Border Radius
  --sleeve-component-border-radius: 0;
  --sleeve-component-image-radius: 0;
  --sleeve-image-radius: 0;
  --sleeve-search-bar-small-border-radius: 0;
  --sleeve-search-bar-small-padding: 0 20px;
  --sleeve-search-bar-label-left: 20px;
  --border-radius-form-elements: 0;
  --sleeve-checkbox-border-radius: 0;
  --sleeve-tag-border-radius: 0;

  --sleeve-page-title-margin-bottom: 60px;
  --sleeve-page-title-margin-bottom-sm: 30px;

  --sleeve-filter-menu-margin-bottom: 60px;

  --header-brand-height-md: 35px;
	--header-brand-height-lg: 60px;

  //- Hightlight & background colors
  --sleeve-color-highlight: var(--color-primary);
  --sleeve-color-highlight-text: var(--color-primary-text);
  --sleeve-color-highlight-tag: var(--color-primary-text);
  --sleeve-color-highlight-arrow: var(--color-primary-text);
  --sleeve-color-highlight-btn: var(--color-primary-text);
  --sleeve-color-highlight-btn-text: var(--color-primary);

  //- Margin
  --section-margin-bottom-md--large: 100px;
  --section-margin-bottom-sm--large: 50px;
  --section-margin-bottom-md--medium: 60px;
  --section-margin-bottom-sm--medium: 30px;
  --section-margin-bottom-md--small: 40px;
  --section-margin-bottom-sm--small: 50px;
  --section-margin-bottom--xsmall: 30px;

  //- Background section color padding
  --bg-section-padding-md-top: 100px;
  --bg-section-padding-sm-top: 50px;
  --bg-section-padding-md-bottom: 100px;
  --bg-section-padding-sm-bottom: 50px;
}
